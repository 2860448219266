<section class="slider-area-2">

    <div class="px-4 py-5 my-5 text-center">
        <img class="d-block mx-auto mb-4" src="assets/icons/agente.png" alt="" width="72" height="57">
        <h1 class="display-5 fw-bold text-body-emphasis">Hola Soy Sofi </h1>
        <div class="col-lg-6 mx-auto">
            <p class="lead mb-4">Para comunicarte con migo haz clic sobre el boton llamar</p>
            <div *ngIf="inCall">
                <div>
                    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div>
                    <h1>En llamada</h1>
                </div>
            </div>
            <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <button type="button" (click)="executeCall()"
                        *ngIf="!inCall" class="btn btn-primary btn-lg px-4 gap-3">
                    <i class="fa fa-phone"></i>Llamar Demo 1</button>
                <button type="button" (click)="executeCall('bf015a5a-53aa-460d-b140-ac5b80a23c30')" *ngIf="!inCall"
                        class="btn btn-primary btn-lg px-4 gap-3"><i class="fa fa-phone">

                </i>Llamar Demo 2</button>
                <button type="button" (click)="setMute()" *ngIf="inCall" class="btn btn-dark btn-lg px-4">{{isMuted ? 'Quitar Mute': 'Mute'}}</button>
                <button type="button" (click)="hangUp()" *ngIf="inCall" class="btn btn-danger btn-lg px-4">Colgar</button>
            </div>
        </div>
    </div>
</section>
