<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img style="width: 130px" src="assets/logo/zeebra-logo.png" alt="logo"/></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+02393970"><i class="bx bxs-phone-call"></i>02393970</a></li>
                        <li><a href="mailto:santiago.viteri@zeebra.tech"><i class="bx bxs-envelope"></i> info&#64;zeebra.tech</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 text-end pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <!--                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>-->
                        <!--                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>-->
                        <!--                        <li><a href="#" target="_blank"> <i class="bx bxs-envelope"></i></a></li>-->
                        <!--                        <li><a href="#" target="_blank"> <i class="bx bxl-youtube"></i></a></li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div
        class="main-nav"
        [ngClass]="{'sticky': isSticky}"
    >
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
<!--                <a class="navbar-brand d-none" routerLink="/">-->
<!--                    <img src="assets/images/logo2.png" alt="logo">-->
<!--                </a>-->
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">


                        <li class="nav-item">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active"
                               [routerLinkActiveOptions]="{exact: true}">Quienes Somos</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Aplicaciones</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Agente virtual</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div class="nav-btn">
                    <a routerLink="/assistant" class="box-btn">Conoce nuestro agente virtual</a>
                </div>
            </nav>
        </div>
    </div>
</div>
