import {Component} from '@angular/core';
import Vapi from '@vapi-ai/web';

@Component({
    selector: 'app-virtual-assistant-view',
    templateUrl: './virtual-assistant-view.component.html',
    styleUrl: './virtual-assistant-view.component.scss'
})
export class VirtualAssistantViewComponent {
    inCall: boolean = false;
    isMuted: boolean = false;
    vapi: Vapi;
    constructor() {
        this.vapi = new Vapi('3d9b956b-66a2-4145-a787-00c2df45fe5b');
    }

    executeCall(squadId?) {
        this.vapi.start(null, {}, squadId || '35450785-86f8-43fd-ac5f-3cc8741a9c4d');

        this.inCall = true;
    }

    hangUp(){
        this.vapi.stop();
        this.inCall = false;
    }

    setMute(){
        this.isMuted = !this.isMuted
        this.vapi.stop();

    }
}
