import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgxScrollTopModule} from 'ngx-scrolltop';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './components/common/navbar/navbar.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {HomeOneComponent} from './components/pages/home-one/home-one.component';
import {HomeTwoComponent} from './components/pages/home-two/home-two.component';
import {HomeThreeComponent} from './components/pages/home-three/home-three.component';
import {AboutComponent} from './components/pages/about/about.component';
import {SolutionsComponent} from './components/pages/solutions/solutions.component';
import {SolutionsDetailsComponent} from './components/pages/solutions-details/solutions-details.component';
import {CaseStudiesComponent} from './components/pages/case-studies/case-studies.component';
import {CaseStudiesDetailsComponent} from './components/pages/case-studies-details/case-studies-details.component';
import {BlogComponent} from './components/pages/blog/blog.component';
import {BlogDetailsComponent} from './components/pages/blog-details/blog-details.component';
import {TeamComponent} from './components/pages/team/team.component';
import {PricingComponent} from './components/pages/pricing/pricing.component';
import {GalleryComponent} from './components/pages/gallery/gallery.component';
import {TestimonialsComponent} from './components/pages/testimonials/testimonials.component';
import {SignUpComponent} from './components/pages/sign-up/sign-up.component';
import {SignInComponent} from './components/pages/sign-in/sign-in.component';
import {ErrorComponent} from './components/pages/error/error.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {TermsConditionsComponent} from './components/pages/terms-conditions/terms-conditions.component';
import {PrivacyPolicyComponent} from './components/pages/privacy-policy/privacy-policy.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {MainHomeComponent} from "./components/pages/landing/main-home/main-home.component";
import {
    VirtualAssistantViewComponent
} from "./components/pages/landing/virtual-assistant-view/virtual-assistant-view.component";

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        MainHomeComponent,
        VirtualAssistantViewComponent,
        HomeOneComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        AboutComponent,
        SolutionsComponent,
        SolutionsDetailsComponent,
        CaseStudiesComponent,
        CaseStudiesDetailsComponent,
        BlogComponent,
        BlogDetailsComponent,
        TeamComponent,
        PricingComponent,
        GalleryComponent,
        TestimonialsComponent,
        SignUpComponent,
        SignInComponent,
        ErrorComponent,
        FaqComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        ContactComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CarouselModule,
        BrowserAnimationsModule,
        NgxScrollTopModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
