<section class="slider-area-2">
    <div class="home-slider">
        <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-1">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Agente Virtual Inteligente</h1>
                                            <p>Soluciones innovadoras para tus necesidades digitales.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/assistant" class="box-btn">Hablemos de tu proyecto</a>
                                                <a routerLink="/assistant" class="box-btn border-btn">Demo</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-2">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Chat bot Inteligente</h1>
                                            <p>Solución en canal de texto para atención a cliente. Podemos integrarla con tu mensajeria instantanea preferida.</p>
                                            <div class="slider-btn text-center">
                                                <a routerLink="/contact" class="box-btn">Hablemos de tu proyecto</a>
                                                <a routerLink="/about" class="box-btn border-btn">Demo</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>

<section class="home-service-area pb-70">
    <div class="container">
        <div class="section-title">
            <span> </span>
            <h2>Somos expertos en tecnologías vanguardistas</h2>
            <p> Lleva a tu negocio al siguiente nivel</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Infraestructura Robusta</h3>
                        <p>Contamos con toda nuestra infraestructura en la nube</p>
                        <a routerLink="/solutions-details" class="line-bnt">Saber más</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Resguardamos sus datos</h3>
                        <p>Nuestro equipo implementa soluciones seguras, para asegurar la información generada</p>
                        <a routerLink="/solutions-details" class="line-bnt">Saber más</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Omnicanalidad</h3>
                        <p>Queremos mantener interconectados todos sus canales</p>
                        <a routerLink="/solutions-details" class="line-bnt">Saber más</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Nuestro procesos</span>
            <h2>Somos populares por nuestra forma de trabajar</h2>
            <p>Acompañamos a nuestros clientes en todo el proceso de desarrollo e implementación</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Levamtamiento</h3>
                        <p>Identificamos sus necesidades y puntos de mejora</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Trabajamos en conjunto con los usuarios expertos</h3>
                        <p>Nuestro equipo se vinculara para conocer y automatizar su proceso</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Desarrollo</h3>
                        <p>El equipo de desarrollo codificará su solución, en base al cronograma establecido </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Despliegue</h3>
                        <p>Una vez culminado el proceso de pruebas, la solución es implementada en ambiente productivo</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Nuestro equipo</span>
            <h2>La gente detras de nuestro trabajo</h2>
            <p>Quienes llevan tu idea a un proyecto</p>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t1.jpg" alt="team" />

                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Santiago Viteri</h3>
                            <p>Arquitectura de software</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t2.jpg" alt="team" />

                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Belen fabara</h3>
                            <p>Desarrollador Senior</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t3.jpg" alt="team" />

                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Daniel Marroquin</h3>
                            <p>Soporte</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t4.jpg" alt="team" />

                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Nataly Fray</h3>
                            <p>Proyectos</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>
